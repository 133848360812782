/* customer.css */

/* Ensure the table is responsive */
.custom-table-responsive {
    overflow-x: auto;
  }
  .customer-row:hover {
    background-color: #f5f5f5; /* Light gray background on hover */
    transition: background-color 0.3s ease; /* Smooth transition effect */
  }
  .custom-table {
    border: 1px solid white;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid white;
  }
  
  /* Adjust the table layout for smaller screens */
  @media (max-width: 768px) {
    .container-fluid {
      padding: 10px;
    }
  
    .custom-table-responsive table {
      width: 100%;
      min-width: 700px; /* Set a minimum width for the table */
    }
  
    .card-body {
      padding: 1rem;
    }
  
    .btn {
      font-size: 0.875rem; /* Reduce button font size for small screens */
    }
  
    /* Stack elements vertically on smaller screens */
    .d-flex {
      flex-direction: column;
      align-items: stretch;
    }
  
    .main-panel {
      padding: 1rem; /* Reduce padding on smaller screens */
    }
  
    .content-wrapper {
      padding: 0.5rem;
    }
  
    .modal-content {
      width: 90%; /* Make the modal full width on smaller screens */
      margin: auto;
    }
  
    /* Adjust table headers for mobile view */
    table#customersTable th, table#customersTable td {
      padding: 8px;
    }
  }
  
  /* Hide the search and filter buttons on small screens */
  @media (max-width: 576px) {
    .d-flex > input,
    .d-flex > .dropdown,
    .d-flex > button {
      margin-bottom: 5px; /* Add spacing between stacked elements */
    }
  }
  /* customer.css */

/* Adjust button alignment for smaller screens */
@media (max-width: 768px) {
    .d-flex.flex-wrap {
      flex-direction: column;
    }
  
    .mb-2 {
      margin-bottom: 10px !important;
    }
  
    .mr-2 {
      margin-right: 0 !important; /* Remove right margin for smaller screens */
    }
  
    .custom-btn {
      width: 200px; /* Full width buttons on small screens */
      margin-bottom: 10px; /* Add some spacing between buttons */
    }
  }
  
  