/* .payslip-container {
    max-width: 1000px;
    
    padding: 20px;
    margin: 0 auto; 
  }
  
  .payslip-header {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    
    
  }
  .payslip-inner {
    background-color: white;
    padding: 20px; 
    border: 1px solid black;
    margin: 50px;

 
  }

  .payslip-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .payslip-employee-details {
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    
  } */
  /* .basic{
    height: 50px;
    border-bottom:none;
  }
  .salarys{
    width: 100%;
     color: #000000;
     
   
  }
.employee-detail{
    display: flex;
    justify-content: space-between;
    color: #000000;
    border-bottom: 1px solid #000;
    padding: 10px 0;
}
  
#st{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
  */

  .employee-details
  {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-top: 1.3px solid #000000d9;
      padding: 3rem 0.10rem;
      border-bottom: 1.3px solid #000000d9;
      color: #000000;
  }

  .employee-details ul li{
    list-style: none;
    padding-top: 12px;
    font-size: 13px;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 2fr 0.20fr 2fr;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    font-weight: 600;
    font-family:'Courier New', Courier, monospace
}
.employee-details ul li span{
    text-align:start;
}
.salary-section h3{
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: #1f1d1dd4;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family:Georgia, 'Times New Roman', Times, serif;
    text-transform: uppercase;
}
.pay{
    color: black;
   }

   .authorization-section
   {
       display: grid;
       grid-template-columns: 2fr 0.80fr;
       padding: 20px;
   }
   .authorization-seal
   {
       text-align: center;
       padding: 1rem;
   }
   .authorization-sign img{
       padding-top: 1rem;
   }
   .authorization-sign p{
    color: #161515;
    font-size: 12px;
    padding-top: 18px;
    font-weight: 500;

   }
   #whatToPrints h1{
    font-size: 23px;
    text-align: center;
    letter-spacing: 1px;
    color: #161515;
    font-weight: 600;
    padding-top: 10px;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.salary-table 
{
    border-top: 1px solid #161515;
    border-bottom: 1px solid #161515;
    color: #2d2d2d;
}
.salary-table  tr td,
    .salary-table  tr th{
        border-right: 1px solid #161515;
    }
    .salary-table  tr td:nth-child(2)
    {
        text-align: center;
    }
    .salary-table  tr td:last-child{
        border-right: none;
        text-align: center;
    }

  
  
 
 
  
  
  

  