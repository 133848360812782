.sidebar {
  min-height: calc(100vh - 70px);
  background: #f4f5f7;
  padding: 0;
  width: 260px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
}

.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 60px;
}

.sidebar .nav .nav-item {
  padding: 0 15px;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
}

.sidebar .nav .nav-item .ReactCollapse--collapse {
  transition: height 500ms;
}

.sidebar .nav .nav-item .collapse {
  z-index: 999;
}

.sidebar .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 15px 0;
  color: #4b5563;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link i {
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-icon {
  font-size: 1.25rem;
  line-height: 1;
  margin-left: auto;
  color: #9ca3af;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  font-size: 1.25rem;
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
  color: #9ca3af;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\f141";
  font-size: inherit;
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}

.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link .badge {
  margin-right: auto;
  margin-left: 1rem;
}

.sidebar .nav .nav-item .nav-link.menu-expanded i.menu-arrow {
  transform: rotate(-90deg);
}

.sidebar .nav .nav-item.active .nav-link .menu-title {
  color: #1f2937;
  font-weight: 500;
}

.sidebar .nav .nav-item.active .nav-link i {
  color: #007bff;
}

.sidebar .nav .nav-item:hover {
  background: #e5e7eb;
}

.sidebar .nav .nav-item.nav-profile .nav-link {
  height: auto;
  line-height: 1;
  border-top: 0;
  padding: 1.25rem 0;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
  width: 44px;
  height: 44px;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
  font-size: 1.125rem;
  margin-left: auto;
}

.sidebar .nav .nav-item.sidebar-actions {
  margin-top: 1rem;
}

.sidebar .nav .nav-item.sidebar-actions .nav-link {
  border-top: 0;
  display: block;
  height: auto;
}

.sidebar .nav .nav-item.sidebar-actions:hover {
  background: initial;
}

.sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
  color: initial;
}

.sidebar .nav .sub-menu {
  margin-bottom: 20px;
  margin-top: 0;
  list-style: none;
}

.sidebar .nav .sub-menu .nav-item {
  padding: 0;
}

.sidebar .nav .sub-menu .nav-item .nav-link {
  color: #6b7280;
  padding: 0.75rem 1.5rem;
  position: relative;
  font-size: 0.75rem;
  line-height: 1;
  height: auto;
  border-top: 0;
}

.sidebar .nav .sub-menu .nav-item .nav-link:before {
  content: "\F054";
  font-family: "Material Design Icons";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
  font-size: 0.75rem;
}

.sidebar .nav .sub-menu .nav-item .nav-link.active {
  color: #1f2937;
  background: transparent;
}

.sidebar .nav .sub-menu .nav-item .nav-link:hover {
  color: #4b5563;
}

.sidebar .nav .sub-menu .nav-item:hover {
  background: transparent;
}

@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 70px);
    top: 70px;
    bottom: 0;
    overflow: auto;
    right: -260px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  .sidebar-offcanvas.active {
    right: 0;
  }
}
