@media (max-width: 768px) {
  #pdfs {
    max-width: 95%;
    padding: 10px;
  }

  #items-table-body {
    font-size: 12px;
  }

  .pcs-itemtable {
    table-layout: auto;
  }
}

@media screen and (max-width: 768px) {
  .rectangle {
    max-width: 100%;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .table-head {
    font-size: 12px;
  }
  
  .pcs-item-row {
    font-size: 14px;
  }
}

