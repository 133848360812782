.container-scroller {
    display: flex;
    flex-direction: column;
  }
  
  .page-body-wrapper {
    display: flex;
    width: 100%;
  }
  
  .main-panel {
    background: #a9a9a961;
    flex-grow: 1;
  }
  
  .card-title h3.uppercase {
    text-transform: uppercase;
  }
  
  .body-wrapper {
    min-height: 100vh;
    padding: 1rem;
  }
  
  .btn-group .btn {
    margin-right: 0.5rem;
  }
  
  .text-center .btn {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
  @media (min-width: 992px) {
  .card-registration-2 .bg-grey {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

@media (max-width: 991px) {
  .card-registration-2 .bg-grey {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.vl {
  border-left: 6px solid gray;
}

.table-row {
  cursor: pointer;
}
.submitShareEmailBtn,
.submitFileAttachBtn,
#commentSaveBtn {
  background: #192d3e;
  border: 1px solid #3a4b5a;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.submitShareEmailBtn:hover,
.submitFileAttachBtn:hover,
#commentSaveBtn:hover {
  background: #3a4b5a;
}
.print-only {
  /* display: none; */
  height: auto;
  width: auto;
  overflow-y: auto;
  border: none;
  /* box-shadow: 5px 2px 5px 2px  grey; */
}
.ribbon .ribbon-inner {
  text-align: center;
  color: #fff;
  top: -20px;
  left: -90px;
  width: 120px;
  padding: 3px;
  /* position: relative;
    transform: rotate(-45deg); */
}
.ribbon .ribbon-open {
  background-color: orange;
  border-color: orange;
}
:root {
  --body-bg: rgb(204, 204, 204);
  --white: #ffffff;
  --darkWhite: #ccc;
  --black: #000000;
  --dark: #615c60;
  --themeColor: #22b8d1;
  --pageShadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
@media print {
  body {
    visibility: hidden !important;
    /* background-color: white; */
  }

  #slip {
    margin-left: 2vh;
    margin-right: 2vh;
  }

  .printContainer {
    visibility: visible !important;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: large;
  }
}
/* Font Include */
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap");

body {
  background-color: var(--body-bg);
}

.page {
  background: var(--white);
  display: block;
  margin: 0 auto;
  position: relative;
  box-shadow: var(--pageShadow);
}

.page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
  overflow: hidden;
}
.pagesizea4 {
    width: 21cm;
    height: 29.7cm;
    overflow: hidden;
  }

.bb {
  border-bottom: 3px solid var(--darkWhite);
}

/* Top Section */
.top-content {
  padding-bottom: 15px;
}

.logo img {
  height: 60px;
}

.top-left p {
  margin: 0;
  color: #000000;
}

.top-left .graphic-path {
  height: 40px;
  position: relative;
}

.top-left .graphic-path::before {
  content: "";
  height: 20px;
  background-color: orange;
  position: absolute;
  left: 15px;
  right: 0;
  top: -15px;
  z-index: 2;
}

.top-left .graphic-path::after {
  content: "";
  height: 22px;
  width: 17px;
  background: orange;
  position: absolute;
  top: -13px;
  left: 6px;
  transform: rotate(45deg);
}

.top-left .graphic-path p {
  /* color: var(--white); */
  height: 40px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  background-color: orange;
  /* font: 26px; */
  z-index: 3;
  position: absolute;
  padding-left: 10px;
}

/* User Store Section */
.store-user {
  padding-bottom: 25px;
}

.store-user p {
  margin: 0;
  font-weight: 600;
  color: #000000;
}

.store-user .address {
  font-weight: 400;
}

.store-user h5 {
  color: var(--themeColor);
  font-family: "Rajdhani", sans-serif;
}

.extra-info p span {
  font-weight: 400;
}

/* Product Section */
#table1 thead {
  color: var(--white);
  background: var(--themeColor);
}

.table td,
.table th {
  text-align: center;
  vertical-align: middle;
}

tr th:first-child,
tr td:first-child {
  text-align: left;
}

/* Balance Info Section */
.balance-info .table td,
.balance-info .table th {
  padding: 0;
  border: 0;
}

.balance-info tr td:first-child {
  font-weight: 600;
}

/* SLIP VIEW STYLINGS */
.slip h5 {
  font-family: serif;
}
p {
  font-size: 1.2em;
}
.address {
  display: flex;
  flex-direction: column;
}
.address p,
.slip-footer p {
  font-size: 1rem;
  margin: 0;
}
.slip-container {
  width: 105mm;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px dotted black;
  box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.35) 0px 2px 6px 2px;
}
.divider {
  margin: 1rem 0;
  border-bottom: 3px dotted black;
}
.trns-id p,
.datetime p,
.createdby p {
  font-size: 0.85rem;
  margin: 0;
}
.equal-length-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: 2vh;
  margin-right: 2vh;
}

.equal-length-item {
  flex: 1;
  text-align: center;
}
