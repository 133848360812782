.bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  
  /* .bar .left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  } */
  
  /* .bar .left input {
    height: 5vh;
  } */
  
  .hidden {
    display: none;
  }
  
  #search-dropdown {
    position: absolute;
  }
  
  @media print {
    * {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
      font-size: 100%;
    }
  
    .bar,
    #nav1,
    header {
      display: none;
    }
  
    #div2 {
      position: absolute;
      top: -100px;
      text-align: center;
      width: 100%;
    }
  }
  #customizeMenu {
    width: fit-content;
    height: 50vh;
    overflow-y: auto;
  }
  
  :root {
    --body-bg: rgb(204, 204, 204);
    --white: #ffffff;
    --darkWhite: #ccc;
    --black: #000000;
    --dark: #615c60;
    --themeColor: #22b8d1;
    --pageShadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  }
  
  
  .page {
    background: var(--white);
    display: block;
    margin: 0 auto;
    position: relative;
    box-shadow: var(--pageShadow);
  }
  
  .page[size="A4"] {
    width: 99%;
    height: 29.7cm;
    overflow: hidden;
    overflow-y: auto;
  }
  #reportTable {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .table-container {
    display: flex;
    align-items: stretch; /* Ensure tables align evenly */
    justify-content: flex-start; /* Align tables to the start with no space */
  }
  
  .table-container table {
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border-collapse: collapse;
    width: 50%; /* Ensure tables fit side by side without overlap */
    border: 0; /* Remove borders between tables */
  }

  .table-container table:first-child {
    margin-right: -1px; /* Slight overlap to eliminate any gap */
  }
  

  #reportTable1 th, #reportTable1 td {
    border: 1px solid #dddddd;
    padding: 5px 2px;
    color: #000;
  }

  #reportTable2 th, #reportTable2 td {
    
  
    border: 1px solid #dddddd;
    padding: 5px 2px;
    color: #000;
  }


  .collapsed-row {
    opacity: 0.5; /* Adjust the value to make it as dull as you want */
  }

  
  
  /* .whatsapp-this{
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 4px 0px;
  }
  
  .whatsapp-this:hover{
  background-color: rgb(255 255 255 / 0.21);
  } */
  
  .submitShareEmailBtn {
    background: #192d3e;
    border: 1px solid #3a4b5a;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  
  .salescontent {
    display: none;
    position: absolute;
    
    min-width: 350px;
    min-height: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    height: fit-content;
    z-index: 1;
  }
  
  .arrow {
    /* font-size: 0.6em; */
    cursor: pointer;
    margin-left: 5px;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
  /* ::-webkit-scrollbar{
    display: none;
  } */
  
  .page::-webkit-scrollbar {
    width: 8px;
  }
  
  .page::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .page::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  

  @media print {
    .table-container {
        position: relative;
    }

    .table-container table:first-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 49%;
    }

    .table-container table:last-child {
        position: absolute;
        top: 0;
        right: 0;
        width: 49%;
    }
}

