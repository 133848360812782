.bar {
  align-items: center;
  display: flex;
  justify-content: space-between;


}

.bar .left {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.bar .left input {
  height: 5vh;
}
  
  
  .hidden {
    display: none;
  }
  
  #search-dropdown {
    position: absolute;
  }
  
  @media print {
    * {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
        font-size: 100%;
  
    }
  
    .bar,
    #nav1,
    header {
        display: none;
    }
  
    #div2 {
        position: absolute;
        top: -100px;
        text-align: center;
        width: 100%;
    }
  
  
  }
  #customizeMenu{
    width: fit-content;
    height: 50vh;
    overflow-y: auto;
  }
  
  :root {
    --body-bg: rgb(204, 204, 204);
    --white: #ffffff;
    --darkWhite: #ccc;
    --black: #000000;
    --dark: #615c60;
    --themeColor: #22b8d1;
    --pageShadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  }
  @media print {
    body{
        visibility: hidden !important;
    }
  
    .printContainer {
        visibility: visible !important;
        position: absolute;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: large;
    }
  
  }
  
  .page {
    background: var(--white);
    display: block;
    margin: 0 auto;
    position: relative;
    box-shadow: var(--pageShadow);
  }
  
  .page[size="A4"] {
    width: 99%;
    height: 29.7cm;
    overflow: hidden;
  }
  #reportTable {
  border-collapse: collapse;
  width: 100%;
  }
  
  #reportTable th, #reportTable td {
  border: 1px solid #dddddd;
  padding: 5px 2px;
  color: #000;
  }
  
  
  .submitShareEmailBtn {
    background: #192d3e;
    border: 1px solid #3a4b5a;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  .submitShareEmailBtn:hover {
    background: #3a4b5a;
  }
  
  .salescontent {
    position: absolute;
    background-color:White;
    min-width: 350px;
    min-height: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    height: fit-content;
    z-index: 1;
    display: none;
  }
  
  .arrow {
    cursor: pointer;
    margin-left: 5px;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
    
    .page::-webkit-scrollbar {
      width: 8px;
    }
    
    .page::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    .page::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
    