/* Base dropdown style */
.dropdown-menu {
  position: absolute;
  right: 0; /* Align to the right end of the button */
  left: auto; /* Override any default left positioning */
  top: 100%; /* Position directly below the button */
  margin-top: 0px; /* No gap between button and dropdown */
  width: 200px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

/* Show dropdown */
.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Slide down */
}

/* Hover effect for items */
.dropdown-menu a {
  padding: 8px 16px;
  display: block;
  text-decoration: none;
  color: #333;
}

.dropdown-menu a:hover {
  background-color: #f1f1f1;
}


  .custom-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .custom-modal-overlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-width: 400px;
  }
  
  .custom-modal-dialog {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 500px;
    animation: fade-in 0.3s ease-out;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .custom-modal-header h5 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #333;
  }
  
  .custom-modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #6c757d;
    transition: color 0.3s ease;
  }
  
  .custom-modal-close:hover {
    color: #007bff;
  }
  
  .custom-modal-body {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }
  
  .custom-modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  
  .custom-modal-footer button {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .custom-modal-footer button:first-of-type {
    background-color: #6c757d;
    color: white;
  }
  
  .custom-modal-footer button:first-of-type:hover {
    background-color: #5a6268;
  }
  
  .custom-modal-footer button:last-of-type {
    background-color: #007bff;
    color: white;
  }
  
  .custom-modal-footer button:last-of-type:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  
  