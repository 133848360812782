@media print {
    body {
      visibility: hidden !important;
    }
  
    .printContainer {
      visibility: visible !important;
      position: absolute;
      z-index: 99999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: medium;
      
      
    }
  }
  /* #printIframe {
      display: block;
  } */
  @keyframes blink {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }
  
  .blinking-text {
    animation: blink 2s step-start infinite;
  }
  .item_data p {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .submitShareEmailBtn,
  #commentSaveBtn {
    background: #e1e6ea;
    border: 1px solid #03090f;
    border-radius: 4px;
    color: rgb(21, 1, 1);
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  .submitShareEmailBtn:hover,
  #commentSaveBtn:hover {
    background: #3a4b5a;
  }
  
  
  