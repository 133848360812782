.msg-count {
  position: absolute;
  left: 11px;
  top: 11px;
  font-size: 8px;
  font-weight: 500;
  width: 14px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
  z-index: 5;
  padding: 0;
  color: #fff;
  background-color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noti-drop-menu {
  width: 280px;
  padding: 0px;
  margin: 0;
  background-color: #f3f3f3 !important;
  right: -30px !important;
}

.notification-dropdown-button::after {
  display: none !important;
}

.noti-msg-header {
  padding: 0.3rem 1.2rem;
  border-bottom: 1px solid rgb(24 24 24 / 15%);
  text-align: center;
  color: #5b4e4e;
  font-weight: bold;
}
.noti-msg-header-title {
  font-size: 20px;
  margin-bottom: 0;
}

.noti-msg-header-subtitle {
  font-size: 14px;
  margin-bottom: 0;
}

.noti-item {
  padding: .72rem 1.2rem !important;
  border-bottom: 1px solid rgb(85 78 78 / 15%) !important;
}

.dropdown-lg .msg-info {
  font-size: 13px;
  margin-bottom: 0;
  text-wrap: pretty;
}

.header-notifications-list {
  position: relative;
  max-height: 420px;
  height: fit-content;
  overflow-y: auto;
}

.header-notifications-list::-webkit-scrollbar {
  display: none;
}

.navDropButtons.btn-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  color: grey;
  font-weight: bold;
  font-size: larger;
}
.navDropButtons.btn-primary:hover,
.navDropButtons.btn-primary:focus,
.navDropButtons.btn-primary:focus-visible,
.navDropButtons.btn-primary:visited {
  background-color: transparent !important;
  border-color: transparent !important;
  color: grey !important;
}
.admin-nav.navbar {
  font-weight: 300;
  background: #fff;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
}

.admin-nav.navbar.navbar-brand {
  width: 110px;
}

.admin-nav.navbar.navbar-brand-wrapper {
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  background: #fff;
  width: 260px;
  height: 70px;
}
@media (min-width: 768px) {
  .navbar .navbar-menu-wrapper {
    width: calc(100% - 260px);
  }
}

/* .navbar .navbar-menu-wrapper {
  width: calc(100% - 260px);
} */
.admin-nav.navbar.navbar-brand-wrapper :host-context(.sidebar-dark) {
  background: #18151e;
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-brand-wrapper {
    width: 55px;
  }
  /* .navbar .navbar-menu-wrapper {
    width: calc(100% - 55px) !important;
  } */
}
.admin-nav.navbar.navbar-brand {
  color: lighten(#0f1531, 20%);
  font-size: 1.5rem;
  line-height: 48px;
  margin-right: 0;
  padding: 0.25rem 0;
  width: 100%;
}
.admin-nav.navbar.navbar-brand:active,
.admin-nav.navbar.navbar-brand:focus,
.admin-nav.navbar.navbar-brand:hover {
  color: lighten(#0f1531, 10%);
}
.admin-nav.navbar.navbar-brand img {
  width: calc(260px - 120px);
  max-width: 100%;
  height: 28px;
  margin: auto;
  vertical-align: middle;
}
.admin-nav.navbar.navbar-brand.brand-logo-mini {
  display: none;
}
.admin-nav.navbar.navbar-brand.brand-logo-mini img {
  width: calc(70px - 50px);
  max-width: 100%;
  height: 28px;
  margin: auto;
}
.admin-nav.navbar.navbar-menu-wrapper {
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #9c9fa6;
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 260px);
  height: 70px;
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-menu-wrapper {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: inherit;
  height: 70px;
  border-radius: 0px;
  padding-left: 5px;
  padding-right: 20px;
}
.admin-nav.navbar.navbar-menu-wrapper
  .navbar-toggler:not(.navbar-toggler-right) {
  font-size: 1.5rem;
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-menu-wrapper
    .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}
/* .admin-nav.navbar.navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
} */
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
    border-right: none;
  }
}
.admin-nav.navbar.navbar-menu-wrapper .search-field .input-group input {
  font-size: 0.875rem;
  padding: 0.5rem;
}
.admin-nav.navbar.navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  font-size: 0.875rem;
  color: #9c9fa6;
  font-weight: 300;
}
.admin-nav.navbar.navbar-menu-wrapper .search-field .input-group i {
  font-size: 17px;
  margin-right: 0;
  color: #9c9fa6;
}
.admin-nav.navbar.navbar-menu-wrapper
  .search-field
  .input-group
  .input-group-text {
  background: transparent;
}
.admin-nav.navbar.navbar-menu-wrapper .search-field .rtl {
  margin-left: 0;
  margin-right: 4px;
}
.admin-nav.navbar.navbar-menu-wrapper .count-indicator {
  position: relative;
}
.admin-nav.navbar.navbar-menu-wrapper .count-indicator .count-symbol,
.admin-nav.navbar.navbar-menu-wrapper .count-indicator .count-number {
  position: absolute;
  border-radius: 100%;
}
.admin-nav.navbar.navbar-menu-wrapper .count-indicator .count-symbol {
  top: 18px;
  right: 0px;
  width: 7px;
  height: 7px;
}
.admin-nav.navbar.navbar-menu-wrapper .count-indicator .count-number {
  min-width: 14px;
  height: 14px;
  font-size: 0.5rem;
  color: #ffffff;
  bottom: 16px;
  right: -5px;
  line-height: 1;
  text-align: center;
}
.admin-nav.navbar.navbar-menu-wrapper .count-indicator:after {
  display: none;
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-nav {
  flex-direction: row;
  align-items: center;
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 0.875rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .admin-nav.navbar.navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
  font-size: 1.25rem;
}
.admin-nav.navbar.navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-toggle {
  border: 0;
  padding: 0;
  background: transparent;
  font-weight: 300;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-toggle:focus {
  box-shadow: none;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-toggle:after {
  color: #b66dff;
  font-size: 1rem;
}
.admin-nav.navbar.navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .rtl {
  right: auto;
  left: 0;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 11px 13px;
  cursor: pointer;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  i {
  font-size: 17px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item
  .rtl
  i {
  margin-right: 0;
  margin-left: 10px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-divider {
  margin: 0;
}
@media (max-width: 767px) {
  .admin-nav.navbar.navbar-menu-wrapper .navbar-nav .nav-item .dropdown {
    position: static;
  }
}
@media (max-width: 767px) {
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .dropdown
    .navbar-dropdown {
    top: 70px;
    width: 100%;
  }
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
  padding-left: 20px;
  padding-right: 5px;
}
.admin-nav.navbar.navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-settings
  .nav-link {
  margin-right: 0;
  margin-left: 0;
  background-color: transparent;
}
.admin-nav.navbar.navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link {
  display: flex;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img {
  position: relative;
  width: 32px;
  height: 32px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  .availability-status {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  bottom: 5px;
  right: -5px;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  .availability-status.online {
  background: #28a745;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  .availability-status.offline {
  background: #dc3545;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-img
  .availability-status.busy {
  background: #ffc107;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text {
  margin-left: 1.25rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text
  .rtl {
  margin-left: 0;
  margin-right: 1.25rem;
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link
  .nav-profile-text
  p {
  line-height: 1;
}
@media (max-width: 767px) {
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-profile
    .nav-link
    .nav-profile-text {
    display: none;
  }
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.nav-profile
  .nav-link.dropdown-toggle:after {
  line-height: 2;
}
/* .admin-nav.navbar.navbar-menu-wrapper .navbar-nav.navbar-nav-right {
} */
@media (min-width: 992px) {
  .admin-nav.navbar.navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }
}
.admin-nav.navbar.navbar-menu-wrapper .navbar-nav.navbar-nav-right .rtl {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 991px) {
  .admin-nav.navbar {
    flex-direction: row;
  }
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-brand-wrapper {
    width: 75px;
  }
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-brand-wrapper .navbar-brand.brand-logo {
    display: none;
  }
}
@media (max-width: 991px) {
  .admin-nav.navbar.navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: inline-block;
  }
}
@media (max-width: 991px) {
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}
@media (max-width: 480px) {
  .admin-nav.navbar.navbar-brand-wrapper {
    width: 55px;
  }
}
@media (max-width: 480px) {
  .admin-nav.navbar.navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}
.navbar.navbar-primary .navbar-menu-wrapper {
  background: linear-gradient(to right, #ff6a00, #ee0979);
  color: #ffffff;
}
.navbar.navbar-primary .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-primary
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper {
  background: linear-gradient(to right, #11998e, #38ef7d);
  color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-success
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper {
  background: linear-gradient(to right, #ffb347, #ffcc33);
  color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-warning
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper {
  background: linear-gradient(to right, #ff512f, #dd2476);
  color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-danger
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper {
  background: linear-gradient(to right, #06beb6, #48b1bf);
  color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-info
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper {
  background: linear-gradient(to right, #232526, #414345);
  color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-dark
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper .nav-profile-text p {
  color: #ffffff;
}
.navbar.navbar-light
  .navbar-menu-wrapper
  .search-field
  .input-group
  input::placeholder {
  color: #ffffff;
}
.navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
  color: #ffffff;
}
