/* General responsive styling */
@media (max-width: 768px) {
  .page {
    padding: 20px;
  }

  .row {
    flex-direction: column;
  }

  .col-4, .col-md-4 {
    width: 100%;
    margin-bottom: 10px;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .h3 {
    font-size: 1.5rem;
  }

  .text-center, .text-right {
    text-align: left;
  }
}

/* Reduce paddings and font sizes for smaller screens */
@media (max-width: 576px) {
  .my-5 {
    margin: 1rem 0;
  }

  .h3 {
    font-size: 1.25rem;
  }

  table {
    font-size: 0.8rem;
  }

  .template2table th, .template2table td {
    padding: 0.25rem;
  }

  .table th, .table td {
    padding: 0.5rem;
  }
}

@media (min-width: 768px) {
  .template2 .page {
    max-width: 80%; /* Reduce the width to 80% for larger screens */
  }
}

